<template>
    <v-container id="BollettaInterattivaFornituraCostiCard5Dt">
        <v-card-title>
            <v-container>
            <v-row>
                <v-col cols="8">
                <v-row align="left">
                    <v-col cols="12" align="left">
                    <p class="text-h5 TitleFornituraCard5Dt">{{translateLabel('message.bolletta_interattiva_template5_costi_title')}}</p>
                    <p class="text-h5 item_consumi_periodo" id="">{{ item.consumi.periodo }}</p>
                    </v-col>
                </v-row>
                <v-row class="FirstContentBollettaFornituraCostiCard5Dt" >
                    <v-col cols="6"></v-col>
                    <v-col justify="center" align="center">
                        <p class="invoices_totale_pagare">{{$t('message.bolletta_interattiva_template5_costi_totale_da_pagare')}}</p>
                        
                        <div v-if="documentData.dati_pagamento_fields.doc_payment_debt.format == 'amount'" class="font-weight-bold px-4 doc_payment_debt_value">
                            {{ documentData.dati_pagamento_fields.doc_payment_debt.value | formatAmount(documentData.dati_pagamento_fields.doc_payment_debt.format_show, documentData.currency_sigle) }}
                        </div>
                        <div v-else-if="documentData.dati_pagamento_fields.doc_payment_debt.format == 'number'" class="font-weight-bold px-4 doc_payment_debt_value">
                            {{ documentData.dati_pagamento_fields.doc_payment_debt.value | formatNumber(documentData.dati_pagamento_fields.doc_payment_debt.format_show) }}
                        </div>
                        <div v-else class="font-weight-bold px-4 doc_payment_debt_value">
                            {{ documentData.dati_pagamento_fields.doc_payment_debt.value }}
                        </div>
                    </v-col>

                </v-row>
                </v-col>
                <v-col cols="4">
                        <img :src="getImg('/images/bollettainterattiva/Template5/Core/img-costi.svg')" />
                </v-col>
            </v-row>
            </v-container>
        </v-card-title>
        <v-row class="SecondContentBollettaFornituraCard5Dt"  >
            <v-col sm="4"></v-col>
            <v-col sm="4" style="text-align: center;">
                <v-btn
                    icon
                    @click="openClose"
                >
                    <span class="costi_show_label" >{{show ?   $t('message.bolletta_interattiva_template5_costi_chiudi') :  $t('message.bolletta_interattiva_template5_costi_espandi')}}</span>

                    <v-icon color="#195aa2">{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
            </v-col>
            <v-col sm="4"></v-col>
        </v-row>
        <v-expand-transition>
        <div v-show="show" id="bolletta_interattiva_storico_consumi_header">
            <div v-for="(voice, i) in item.costi" :key="'costi_voice-' + i"  :class="'costi_voice costi_voice-' + i" > 
            <v-row> 
                <v-col cols="4">
                    <p class="voice_costi_label">{{translateLabel('message.bolletta_interattiva_template5_costi_voices.' + voice.lbl,voice.lbl)}}</p>
                </v-col> 
                <v-col cols="4">
                    <v-tooltip v-if="voice.tooltip" color="#195aa2" top>
                        <template v-slot:activator="{ on }">
                            <img v-on="on" width="10%" :src="getImg('/images/bollettainterattiva/Template5/Core/ico-search.svg')" />
                        </template>
                        <span class="white--text" >{{translateLabel('message.bolletta_interattiva_template5_costi_voices_tooltip.' + voice.tooltip,voice.tooltip)}}</span>
                    </v-tooltip>
                </v-col>
                <v-col cols="4" v-if="voice.importo" >
                    <span class="voice_costi_value">{{voice.importo}}</span>
                </v-col>
            </v-row>
            <v-row v-if="voice.importo">
                <div class="costi-barra-container">
                    <div class="costi-barra-bg">
                    </div> 
                    <div  :style="{width: getWidthBar(voice.importo)}"  :class="(voice.importo>0) ? 'costi-barra-color-blue' : 'costi-barra-color-green'">
                    </div>
                </div>
            </v-row>
            </div>
        </div>
        </v-expand-transition>        
       <!--  <div v-if="documentData.document_payment_expiry">
            <span>{{translateLabel('message.bolletta_interattiva_template5_costi_first')}}</span>
            <b>{{documentData.dati_pagamento_fields.doc_payment_expiry.value | formatDate(documentData.dati_pagamento_fields.doc_payment_expiry.format_show, $t("message.contract_date_not_available"))}}</b>
            <span class="show_dialog_info" @click="scaricaPromemoria()" style="cursor: pointer;">
                <img :src="getImg('/images/bollettainterattiva/Template5/Core/ico-campana.svg')" id="ico-campana"/>{{translateLabel('message.bolletta_interattiva_template5_costi_second')}}
            </span>
        </div>
        <v-row id="BollettacostiSoldi" justify="left" align="center" >
            <v-col cols="2">
                <img :src="getImg('/images/bollettainterattiva/Template5/Core/ico-soldi.svg')" id="ico-soldi"/>   
            </v-col>
            <v-col cols="10">
                <p style="text-align: left;" id="SpanBollettaCostiSoldi">{{translateLabel('message.bolletta_interattiva_template5_costi_third')}}</p>
            </v-col>
        </v-row>                     
        <v-row class="SecondContentBollettaInterattivaWebTile5Dt d-flex" justify="center" align="center" >
            <v-btn
                class="bollettaInterattiva5_webButton white--text"
                id="ButtonBollettaCostiSoldi_L"
                    @click="pagaOra()"
                    x-large
                    color="black"
                    >
                    <span class="bollettaInterattiva5_webButton_content"
                    v-html="$t('message.bolletta_interattiva_template5_costi_button_first')"></span> 
            </v-btn>
            <v-btn
                class="bollettaInterattiva5_webButton white--text"
                id="ButtonBollettaCostiSoldi_R"
                    @click="bollettaPDF()"
                    x-large
                    color="black"
                    >
                    <span class="bollettaInterattiva5_webButton_content"
                    v-html="$t('message.bolletta_interattiva_template5_costi_button_second')"></span> 
            </v-btn>
        </v-row>
        
        <v-row class="SecondContentBollettaInterattivaInfoPagamento5Dt d-flex" justify="center" align="center" style="cursor: pointer;">
            <span class="show_dialog_info"
            @click="paymentMethod()">{{translateLabel('message.bolletta_interattiva_template5_info_pagamento_button_label')}}</span>
        </v-row> -->
    </v-container>
</template>

<script>
import { services } from '../../../scripts/services/serviceBuilder';
import { createEvent} from 'ics';

export default {
    name: 'BollettaInterattivaFornituraCostiCard5Dt',
    data: function() {
    return {
        showDialog: false,
        show: false,

    }},
    props: {
        item: {},
        documentData: {
            type: Object,
            default: null
        },
        translateLabel: {
            type: Function,
        },
        getImg: {
            type: Function,
        },
        //show: Boolean,
    },
    computed: {

    },
    components: {
    },
    methods: {
        openClose() {
            let that = this;
            //that.$emit("openClose", that.$vnode.key);
            that.show = !that.show;
        }, 
        getWidthBar(importo){
            let perc = ( Math.abs(parseInt(importo))/(parseInt(this.documentData.dati_pagamento_fields.doc_payment_debt.value) / 100));
            if(perc > 100)
                perc = 100;
            else
                if(perc<0) 
                    perc = 0;
            return perc + '%';
        },
        scaricaPromemoria: async function(){
            //Generate ICS file
            let lastDayOfCurrentMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
            let firstDayofNextMonth = new Date(lastDayOfCurrentMonth.valueOf());
            firstDayofNextMonth.setDate(firstDayofNextMonth.getDate() + 1);
            console.log(firstDayofNextMonth);
            let alarm = [{ action: 'display', description: 'Reminder', trigger: { hours: 24, minutes: 0, before: true } }];
            const event = {
                productId:"UnoEnergy",
                start: [lastDayOfCurrentMonth.getFullYear(), lastDayOfCurrentMonth.getMonth()+1, lastDayOfCurrentMonth.getDate(), 11, 0],
                title: "Effettua l'autolettura gas.",
                description: "Ricordati di effettuare l'autolettura gas.",
                status: 'CONFIRMED',
                recurrenceRule: 'FREQ=MONTHLY;BYMONTHDAY=-1;UNTIL='+firstDayofNextMonth.getFullYear()+firstDayofNextMonth.getMonth()+1+firstDayofNextMonth.getDate()+'T000000',
                alarms: alarm
            }
        

            const filename = 'Promemoria Autolettura.ics'
            const file = await new Promise((resolve, reject) => {
                createEvent(event, (error, value) => {
                if (error) {
                    reject(error)
                }
                
                resolve(new File([value], filename, { type: 'plain/text' }))
                })
            })
            const url = URL.createObjectURL(file);
            
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = filename;
            
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
            
            URL.revokeObjectURL(url);

        },
        pagaOra: function(){
            //window.open('https://variazioni-contrattuali.unoenergy.it/bolletta-digitale')
        },
        paymentMethod: function(){
            window.open('https://www.unoenergy.it/info-sul-pagamento/ ')
        },
        bollettaPDF: function(){
            var that = this;

            services.apiCallerMedia.download(that.documentData.detail)
            .then(response =>  {
                const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
                window.open(url, '_blank');
                setTimeout(() => {
                    window.URL.revokeObjectURL(url);
                }, 100);
            })               
            .catch(error => {
                console.log(error);
            });
        }
    },
}
</script>
